import React, { useState, useEffect } from "react";
import "../style/event_editor.scss";
import { Button, Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMutation } from "urql";
import ImageUploader from "./ImageUploader";

const UpdateEvent = `
  mutation ($id: ID!, $enTitle: String!, $frTitle: String!, $enDescription: String!, $frDescription: String!, $img: String!) {
    updateEvent (id: $id, enTitle: $enTitle, frTitle: $frTitle, enDescription: $enDescription, frDescription: $frDescription, img: $img) {
      id
      enTitle
    }
  }
`;

const CreateEvent = `
  mutation ($enTitle: String!, $frTitle: String!, $enDescription: String!, $frDescription: String!, $img: String!) {
    createEvent (enTitle: $enTitle, frTitle: $frTitle, enDescription: $enDescription, frDescription: $frDescription, img: $img) {
      id
      enTitle
    }
  }
`;

const RemoveEvent = `
  mutation ($id: ID!) {
    removeEvent (id: $id)
  }
`;

const EventEditor = ({
  eventObject = {},
  newMode,
  eventOnCreate,
  eventOnDelete,
}) => {
  const [enTitle, setEnTitle] = useState("");
  const [frTitle, setFrTitle] = useState("");
  const [enDescription, setEnDescription] = useState("");
  const [frDescription, setFrDescription] = useState("");
  const [uploaderImagePreviewUrl, setUploaderImagePreviewUrl] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [updateEventResult, updateEvent] = useMutation(UpdateEvent);
  const [createEventResult, createEvent] = useMutation(CreateEvent);
  const [removeEventResult, removeEvent] = useMutation(RemoveEvent);

  useEffect(() => {
    setEnTitle(eventObject.enTitle);
    setFrTitle(eventObject.frTitle);
    setEnDescription(eventObject.enDescription);
    setFrDescription(eventObject.frDescription);
    setImagePreviewUrl(eventObject.img);
  }, [
    eventObject.enDescription,
    eventObject.enImg,
    eventObject.enTitle,
    eventObject.frDescription,
    eventObject.frTitle,
    eventObject.img,
    newMode,
  ]);

  const eventPayload = () => {
    return {
      id: newMode ? null : eventObject.id,
      enTitle: enTitle,
      frTitle: frTitle,
      enDescription: enDescription,
      frDescription: frDescription,
      img: uploaderImagePreviewUrl || imagePreviewUrl,
    };
  };

  const onUpdate = (e) => {
    e.preventDefault();

    updateEvent(eventPayload()).then((res) => {
      if (res.error) {
        toast.error("Please make sure all inputs are valid.");
      } else {
        setUploaderImagePreviewUrl(null);
        toast.success("Event is updated");
      }
    });
  };

  const onCreate = () => {
    createEvent(eventPayload()).then((res) => {
      if (res.error) {
        toast.error("Please make sure all inputs are valid.");
      } else {
        toast.success("Event is created");
        eventOnCreate();
      }
    });
  };

  const onDelete = () => {
    removeEvent({
      id: eventObject.id,
    }).then((res) => {
      if (res.error) {
        toast.error("Unable to remove event, please try again later.");
      } else {
        toast.success("Event has been removed");
        eventOnDelete();
      }
    });
  };

  return (
    <Form className="event-editor">
      <Form.Group>
        <Form.Label>Event {eventObject.id} Image </Form.Label>
        {imagePreviewUrl && (
          <div className="image_preview_container">
            <img
              className="image_preview"
              src={imagePreviewUrl}
              alt="image_preview"
            />
          </div>
        )}

        <ImageUploader
          key={Math.random()} // Force remount component once image is uploaded
          imageOnUploaded={(fileUrl) => setUploaderImagePreviewUrl(fileUrl)}
        />
        {uploaderImagePreviewUrl && (
          <div className="image_uploader_preview_container">
            <img
              className="image_uploader_preview"
              src={uploaderImagePreviewUrl}
              alt="image_preview"
            />
          </div>
        )}
      </Form.Group>
      <Form.Group>
        <Row>
          <Col>
            <Form.Label>Event {eventObject.id} Title (English) </Form.Label>
            <Form.Control
              type="text"
              placeholder="Event Title"
              value={enTitle}
              onChange={(e) => setEnTitle(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Label>Event {eventObject.id} Title (French) </Form.Label>
            <Form.Control
              type="text"
              placeholder="Event Title"
              value={frTitle}
              onChange={(e) => setFrTitle(e.target.value)}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col>
            <Form.Label>
              Event {eventObject.id} Description (English)
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Event Description"
              value={enDescription}
              onChange={(e) => setEnDescription(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Label>Event {eventObject.id} Description (French)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Event Description"
              value={frDescription}
              onChange={(e) => setFrDescription(e.target.value)}
            />
          </Col>
        </Row>
      </Form.Group>

      <Button variant="primary" onClick={newMode ? onCreate : onUpdate}>
        {newMode ? "Save" : "Update"}
      </Button>

      {!newMode && (
        <Button variant="danger" onClick={onDelete} className="remove-button">
          Remove
        </Button>
      )}
    </Form>
  );
};

export default EventEditor;
