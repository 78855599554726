import React, { useState, useEffect } from "react";
import "../style/word_of_editor.scss";
import { Button, Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMutation } from "urql";

const UpdateWordOfLove = `
  mutation ($id: ID!, $enContent: String!, $frContent: String!, $author: String!, $by: String!) {
    updateWordOfLove (id: $id, enContent: $enContent, frContent: $frContent, author: $author, by: $by) {
      id
    }
  }
`;

const UpdateWordOfWisdom = `
  mutation ($id: ID!, $enContent: String!, $frContent: String!, $author: String!, $by: String!) {
    updateWordOfWisdom (id: $id, enContent: $enContent, frContent: $frContent, author: $author, by: $by) {
      id
    }
  }
`;

const WordOfEditor = ({ isWordOfLove, wordOfObject = {} }) => {
  const [author, setAuthor] = useState("");
  const [enContent, setEnContent] = useState("");
  const [frContent, setFrContent] = useState("");
  const [by, setBy] = useState("");
  const [updateWordOfLoveResult, updateWordOfLove] = useMutation(
    UpdateWordOfLove
  );
  const [updateWordOfWisdomResult, updateWordOfWisdom] = useMutation(
    UpdateWordOfWisdom
  );

  const getWordOfPayload = () => {
    return {
      id: 1,
      enContent: enContent,
      frContent: frContent,
      author: author,
      by: by,
    };
  };

  useEffect(() => {
    setAuthor(wordOfObject.author);
    setEnContent(wordOfObject.enContent);
    setFrContent(wordOfObject.frContent);
    setBy(wordOfObject.by);
  }, [
    wordOfObject.author,
    wordOfObject.enContent,
    wordOfObject.frContent,
    wordOfObject.by,
  ]);

  const onUpdate = (e) => {
    e.preventDefault();

    const updateQuery = isWordOfLove ? updateWordOfLove : updateWordOfWisdom;
    updateQuery(getWordOfPayload()).then((res) => {
      if (res.error) {
        toast.error("Please make sure all inputs are valid.");
      } else {
        if (isWordOfLove) {
          toast.success("Word of Love is updated");
        } else {
          toast.success("Word of Wisdom is updated");
        }
      }
    });
  };

  return (
    <Form className="word-of-editor">
      <Form.Group>
        <Row>
          <Col>
            <Form.Label> Content (English) </Form.Label>
            <Form.Control
              type="text"
              placeholder={isWordOfLove ? "Word Of Love" : "Word Of Wisdom"}
              value={enContent}
              onChange={(e) => setEnContent(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Label> Content (French) </Form.Label>
            <Form.Control
              type="text"
              placeholder={isWordOfLove ? "Word Of Love" : "Word Of Wisdom"}
              value={frContent}
              onChange={(e) => setFrContent(e.target.value)}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col>
            <Form.Label> By </Form.Label>
            <Form.Control
              type="text"
              placeholder="By"
              value={by}
              onChange={(e) => setBy(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Label> Author </Form.Label>
            <Form.Control
              type="text"
              placeholder="Author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </Col>
        </Row>
      </Form.Group>

      <Button variant="primary" onClick={onUpdate}>
        Update
      </Button>
    </Form>
  );
};

export default WordOfEditor;
