export const getServerUrl = () => {
  if (window.location.hostname == "boxia-admin.boxia.ca") {
    return "https://boxia-core.boxia.ca";
  } else {
    if (process.env.NODE_ENV === "production") {
      return "https://boxia-core.dokku.explorator.ca";
    } else {
      return "http://localhost:4000";
    }
  }
};

export const getClientUrl = () => {
  if (window.location.hostname == "boxia-admin.boxia.ca") {
    return "https://boxia.boxia.ca";
  } else {
    if (process.env.NODE_ENV === "production") {
      return "https://boxia.dokku.explorator.ca";
    } else {
      return "http://localhost:3000";
    }
  }
};
