import React, { useEffect, useState } from "react";
import "./style/management_panel.scss";
import { Container, Card, Collapse, Button } from "react-bootstrap";
import EventEditor from "./dashboard_components/EventEditor";
import ComicEditor from "./dashboard_components/ComicEditor";
import DashboardNavBar from "./dashboard_components/DashboardNavBar";
import WordOfEditor from "./dashboard_components/WordOfEditor";
import CulturalBillboardEditor from "./dashboard_components/CulturalBillboardEditor";
import WhatsNewEditor from "./dashboard_components/WhatsNewEditor";
import EbookCustomersPanel from "./dashboard_components/EbookCustomersPanel";
import { useQuery } from "urql";

const ManagementPanel = ({ clientUrl }) => {
  const GetExistingData = `
    query {
      allEvents {
        id
        enTitle
        frTitle
        enDescription
        frDescription
        img
      }
      allComicOfTheDay {
        id
        enTitle
        frTitle
        enDescription
        frDescription
        enImg
        frImg
      }
      wordOfLove {
        id
        author
        by
        enContent
        frContent
      }
      wordOfWisdom {
        id
        author
        by
        enContent
        frContent
      }
      culturalBillboard{
        id
        image
        enTitle
        frTitle
      }
      whatsNew{
        enContent
        frContent
      }
      allCustomers{
        id
        email
        ebooks {
          id
          displayName
        }
      }
      allEbooks{
        id
        displayName
      }
    }
  `;

  const [getExistingData, reGetExistingData] = useQuery({
    query: GetExistingData,
  });

  const [allEvents, setAllEvents] = useState([]);
  const [allComic, setAllComic] = useState([]);
  const [allEbooks, setAllEbooks] = useState([]);
  const [wordOfLove, setWordOfLove] = useState([]);
  const [wordOfWisdom, setWordOfWisdom] = useState([]);
  const [whatsNew, setWhatsNew] = useState([]);
  const [culturalBillboard, setCulturalBillboard] = useState([]);
  const [eBookCustomers, setEBookCustomers] = useState([]);

  const [loading, setLoading] = useState(true);

  const [comicPanelOpen, setComicPanelOpen] = useState(false);
  const [eventPanelOpen, setEventPanelOpen] = useState(false);
  const [wordOfLovePanelOpen, setWordOfLovePanelOpen] = useState(false);
  const [wordOfWisdomPanelOpen, setWordOfWisdomPanelOpen] = useState(false);
  const [culturalBillboardPanelOpen, setCulturalBillboardPanelOpen] = useState(
    false
  );
  const [whatsNewPanelOpen, setWhatsNewPanelOpen] = useState(false);
  const [eBookCustomerPanelOpen, setEBookCustomerPanelOpen] = useState(false);

  const [addingComic, setAddingComic] = useState(false);
  const [addingEvent, setAddingEvent] = useState(false);

  useEffect(() => {
    const existingData = getExistingData.data;
    if (existingData) {
      setAllEvents(existingData.allEvents);
      setAllComic(existingData.allComicOfTheDay);
      setWordOfLove(existingData.wordOfLove[0]);
      setWordOfWisdom(existingData.wordOfWisdom[0]);
      setCulturalBillboard(existingData.culturalBillboard[0]);
      setWhatsNew(existingData.whatsNew[0]);
      setEBookCustomers(existingData.allCustomers);
      setAllEbooks(existingData.allEbooks);
      setLoading(false);
    }
  }, [getExistingData]);

  const eventAddOnClick = (e) => {
    e.preventDefault();
    setAddingEvent(true);
  };

  const eventOnCreate = () => {
    reGetExistingData({ requestPolicy: "network-only" });
    setAddingEvent(false);
  };

  const eventOnDelete = () => {
    reGetExistingData({ requestPolicy: "network-only" });
  };

  const comicAddOnClick = () => {
    setAddingComic(true);
  };

  const comicOnCreate = () => {
    reGetExistingData({ requestPolicy: "network-only" });
    setAddingComic(false);
  };

  const comicOnDelete = () => {
    reGetExistingData({ requestPolicy: "network-only" });
  };

  const customerOnCreate = () => {
    reGetExistingData({ requestPolicy: "network-only" });
  };

  return (
    <div id="management-panel">
      <DashboardNavBar clientUrl={clientUrl} />
      <Container>
        {loading ? (
          <div>Loading ...</div>
        ) : (
          <>
            <Card>
              <Card.Header onClick={() => setComicPanelOpen(!comicPanelOpen)}>
                Comics
              </Card.Header>
              <Collapse in={comicPanelOpen}>
                <Card.Body>
                  <div className="editor-card-body">
                    {addingComic ? (
                      <Card className="comic-card">
                        <Card.Header>New Comic</Card.Header>
                        <Card.Body>
                          <ComicEditor
                            newMode={true}
                            comicOnCreate={comicOnCreate}
                          />
                        </Card.Body>
                      </Card>
                    ) : (
                      <Button onClick={comicAddOnClick} className="add-button">
                        Add Comic
                      </Button>
                    )}

                    {allComic.map((comic) => (
                      <Card className="comic-card" key={comic.id}>
                        <Card.Header>Comic {comic.id}</Card.Header>
                        <Card.Body>
                          <ComicEditor
                            comicObject={comic}
                            comicOnDelete={comicOnDelete}
                          />
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                </Card.Body>
              </Collapse>
            </Card>

            <Card>
              <Card.Header onClick={() => setEventPanelOpen(!eventPanelOpen)}>
                Events
              </Card.Header>
              <Collapse in={eventPanelOpen}>
                <Card.Body>
                  <div className="editor-card-body">
                    {addingEvent ? (
                      <Card className="event-card">
                        <Card.Header>New Event</Card.Header>
                        <Card.Body>
                          <EventEditor
                            newMode={true}
                            eventOnCreate={eventOnCreate}
                          />
                        </Card.Body>
                      </Card>
                    ) : (
                      <Button onClick={eventAddOnClick} className="add-button">
                        Add Event
                      </Button>
                    )}

                    {allEvents.map((event) => (
                      <Card className="event-card" key={event.id}>
                        <Card.Header>Event {event.id}</Card.Header>
                        <Card.Body>
                          <EventEditor
                            eventObject={event}
                            eventOnDelete={eventOnDelete}
                          />
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                </Card.Body>
              </Collapse>
            </Card>

            <Card>
              <Card.Header
                onClick={() => setWordOfLovePanelOpen(!wordOfLovePanelOpen)}
              >
                Word Of Love
              </Card.Header>
              <Collapse in={wordOfLovePanelOpen}>
                <Card.Body>
                  <div className="editor-card-body">
                    <WordOfEditor
                      isWordOfLove={true}
                      wordOfObject={wordOfLove}
                    />
                  </div>
                </Card.Body>
              </Collapse>
            </Card>

            <Card>
              <Card.Header
                onClick={() => setWordOfWisdomPanelOpen(!wordOfWisdomPanelOpen)}
              >
                Word Of Wisdom
              </Card.Header>
              <Collapse in={wordOfWisdomPanelOpen}>
                <Card.Body>
                  <div className="editor-card-body">
                    <WordOfEditor
                      isWordOfLove={false}
                      wordOfObject={wordOfWisdom}
                    />
                  </div>
                </Card.Body>
              </Collapse>
            </Card>

            <Card>
              <Card.Header
                onClick={() =>
                  setCulturalBillboardPanelOpen(!culturalBillboardPanelOpen)
                }
              >
                Cultural Billboard
              </Card.Header>
              <Collapse in={culturalBillboardPanelOpen}>
                <Card.Body>
                  <div className="editor-card-body">
                    <CulturalBillboardEditor
                      culturalBillboardObject={culturalBillboard}
                    />
                  </div>
                </Card.Body>
              </Collapse>
            </Card>

            <Card>
              <Card.Header
                onClick={() => setWhatsNewPanelOpen(!whatsNewPanelOpen)}
              >
                What's New
              </Card.Header>
              <Collapse in={whatsNewPanelOpen}>
                <Card.Body>
                  <div className="editor-card-body">
                    <WhatsNewEditor whatsNew={whatsNew} />
                  </div>
                </Card.Body>
              </Collapse>
            </Card>

            <Card>
              <Card.Header
                onClick={() =>
                  setEBookCustomerPanelOpen(!eBookCustomerPanelOpen)
                }
              >
                E Book customers
              </Card.Header>
              <Collapse in={eBookCustomerPanelOpen}>
                <Card.Body>
                  <div className="editor-card-body">
                    <EbookCustomersPanel
                      ebookCustomers={eBookCustomers}
                      customerOnCreate={customerOnCreate}
                      allEbooks={allEbooks}
                    />
                  </div>
                </Card.Body>
              </Collapse>
            </Card>
          </>
        )}
      </Container>
    </div>
  );
};

export default ManagementPanel;
