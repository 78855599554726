import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import { useMutation } from "urql";
import "../style/image_uploader.scss";

const GetPresignUrl = `
  mutation ($filename: String!){
    getPresignUrl(filename: $filename)
  }
`;

const ImageUploader = ({ imageOnUploaded }) => {
  const [getPresignUrlResult, getPresignUrl] = useMutation(GetPresignUrl);
  const [uploadMessage, setUploadMessage] = useState(
    "Upload to replace the existing image."
  );

  const compressImage = async (event, useWebWorker) => {
    const imageFile = event.target.files[0];
    console.log("input", imageFile);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      setUploadMessage("Compressing image ...");
      const presignUrlRequest = JSON.parse(
        (await getPresignUrl({ filename: imageFile.name })).data.getPresignUrl
      );
      const presignedUrl = presignUrlRequest.presigned_url;
      const objectUrl = presignUrlRequest.object_url;

      const compressedFile = await imageCompression(imageFile, options);

      setUploadMessage("Uploading to Boxia ...");

      const uploadS3Response = await fetch(presignedUrl, {
        method: "PUT",
        body: compressedFile,
      });

      if (!uploadS3Response.ok) {
        throw new Error(
          `${uploadS3Response.status}: ${uploadS3Response.statusText}`
        );
      } else {
        imageOnUploaded(objectUrl);
        setUploadMessage("Image uploaded, click 'Update' to save");
      }
    } catch (error) {
      setUploadMessage(`Unable to upload image: ${error}`);
    }
  };

  return (
    <section className="container image-uploader">
      <input
        type="file"
        accept="image/*"
        onChange={(e) => compressImage(e, false)}
      />
      <div>
        <b>{uploadMessage}</b>
      </div>
    </section>
  );
};

export default ImageUploader;
