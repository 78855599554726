import React, { useState } from "react";
import "../style/ebook_customers_panel.scss";
import { Button, Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getServerUrl } from "../Utils";
import axios from "axios";
import Cookies from "js-cookie";
import ComicBookSelector from "./EbookCustomerPanelComponents/ComicBookSelector";

const EbookCustomersPanel = ({
  ebookCustomers = [],
  customerOnCreate,
  allEbooks,
}) => {
  const [newUserEmail, setNewUserEmail] = useState();
  const [creatingCustomer, setCreatingCustomer] = useState(false);

  const resetPasswordButtonOnClick = (customerEmail) => {
    const authToken = Cookies.get("auth-token");
    axios
      .post(
        `${getServerUrl()}/reset_password`,
        {
          customer_email: customerEmail,
        },
        {
          headers: { authorization: `Bearer ${authToken}` },
        }
      )
      .then(() =>
        toast.success(
          `New password has been generated and sent to ${customerEmail}`
        )
      )
      .catch(() =>
        toast.error(
          `Unable to reset password for ${customerEmail}, please try again later or contact support.`
        )
      );
  };

  const addNewUserButtonOnClick = () => {
    const authToken = Cookies.get("auth-token");
    setCreatingCustomer(true);

    axios
      .post(
        `${getServerUrl()}/create_new_user`,
        {
          new_user_email: newUserEmail,
        },
        {
          headers: { authorization: `Bearer ${authToken}` },
        }
      )
      .then(() => {
        toast.success(
          `Customer has been created and password has sent to ${newUserEmail}`
        );
        customerOnCreate();
        setNewUserEmail("");
      })
      .catch(() =>
        toast.error(
          `Unable to create new customer (${newUserEmail}), please make sure email is correct or contact support`
        )
      )
      .then(() => setCreatingCustomer(false));
  };

  return (
    <Form className="ebook-customers-panel">
      <Row className="new-customer-row">
        <Col className="email-input-field-container col-sm-8">
          <Form.Control
            type="email"
            placeholder="Enter new user's email"
            onChange={(e) => setNewUserEmail(e.target.value)}
            value={newUserEmail}
          />
        </Col>
        <Col className="col-sm-4">
          <Button onClick={addNewUserButtonOnClick} disabled={creatingCustomer}>
            Create customer
          </Button>
        </Col>
      </Row>
      <hr />
      <Form.Group>
        {ebookCustomers.map((customer) => (
          <div key={customer.id}>
            <Row className="customer-info-row">
              <Col>{customer.email}</Col>
              <Col>
                <ComicBookSelector
                  customerId={customer.id}
                  ebooksArray={customer.ebooks}
                  allEbooksArray={allEbooks}
                />
              </Col>
              <Col>
                <Button
                  onClick={() => resetPasswordButtonOnClick(customer.email)}
                  variant="warning"
                >
                  Reset password
                </Button>
              </Col>
            </Row>
          </div>
        ))}
      </Form.Group>
    </Form>
  );
};

export default EbookCustomersPanel;
