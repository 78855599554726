import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useMutation } from "urql";
import { toast } from "react-toastify";

const addRemoveOperationQuery = `
  mutation removeAddEbookOnCustomer (
    $userId: ID!,
    $ebookId: ID!,
    $operation: String!
  ) {
      removeAddEbookOnCustomer(
        userId: $userId, 
        ebookId: $ebookId,
        operation: $operation
      ) {
      ebooks{
        id
        name
        language
      }
    }
  }
`;

const ComicBookSelector = ({ customerId, ebooksArray, allEbooksArray }) => {
  const [customerEbooksIds, setCustomerEbooksIds] = useState([]);
  const [addRemoveEbook, runAddRemoveEbook] = useMutation(
    addRemoveOperationQuery
  );

  useEffect(() => {
    setCustomerEbooksIds(ebooksArray.map((ebook) => ebook.id));
  }, []);

  // Determine is this OnChange operation an 'add' or 'remove', then fire corresponding request to backend
  const ebookSelectionOnChange = (updatedEbooks) => {
    const operation =
      updatedEbooks.length > customerEbooksIds.length ? "add" : "remove";
    const updatedBooksArrayIds = updatedEbooks.map((ebook) => ebook.value);

    const addDifference = customerEbooksIds.filter(
      (ebookId) => !updatedBooksArrayIds.includes(ebookId)
    );
    const removeDifference = updatedBooksArrayIds.filter(
      (ebookId) => !customerEbooksIds.includes(ebookId)
    );

    let addRemoveEbookId;
    if (addDifference.length === 0) {
      addRemoveEbookId = removeDifference[0];
    } else {
      addRemoveEbookId = addDifference[0];
    }
    setCustomerEbooksIds(updatedEbooks.map((ebook) => ebook.value));

    runAddRemoveEbook({
      userId: customerId,
      ebookId: addRemoveEbookId,
      operation: operation,
    }).then((result) => {
      if (result.error) {
        toast.error("Unable to update ebook selection, please contact support");
      } else {
        toast.success("Updated comic book selection");
      }
    });
  };

  const getExistingEbooksMapping = () => {
    const customerEbooks = allEbooksArray.filter((existingEbook) =>
      ebooksArray.find((ebook) => ebook.id == existingEbook.id)
    );
    return customerEbooks.map((ebook) => ({
      value: ebook.id,
      label: ebook.displayName,
    }));
  };

  return (
    <>
      <Select
        isMulti
        isClearable={false}
        defaultValue={getExistingEbooksMapping()}
        options={allEbooksArray.map((ebook) => ({
          value: ebook.id,
          label: ebook.displayName,
        }))}
        onChange={ebookSelectionOnChange}
      />
    </>
  );
};

export default ComicBookSelector;
