import React, { useState, useEffect } from "react";
import "../style/comic_editor.scss";
import { Button, Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMutation } from "urql";
import ImageUploader from "./ImageUploader";

const UpdateComic = `
  mutation ($id: ID!, $enTitle: String!, $frTitle: String!, $enDescription: String!, $frDescription: String!, $enImg: String!, $frImg: String!) {
    updateComicOfTheDay (id: $id, enTitle: $enTitle, frTitle: $frTitle, enDescription: $enDescription, frDescription: $frDescription, enImg: $enImg, frImg: $frImg) {
      id
      enTitle
    }
  }
`;

const CreateComic = `
  mutation ($enTitle: String!, $frTitle: String!, $enDescription: String!, $frDescription: String!, $enImg: String!, $frImg: String!) {
    createComicOfTheDay (enTitle: $enTitle, frTitle: $frTitle, enDescription: $enDescription, frDescription: $frDescription, enImg: $enImg, frImg: $frImg) {
      id
      enTitle
    }
  }
`;

const RemoveComic = `
  mutation ($id: ID!) {
    removeComicOfTheDay (id: $id)
  }
`;

const ComicEditor = ({
  comicObject = {},
  newMode,
  comicOnCreate,
  comicOnDelete,
}) => {
  const [enTitle, setEnTitle] = useState("");
  const [frTitle, setFrTitle] = useState("");
  const [enDescription, setEnDescription] = useState("");
  const [frDescription, setFrDescription] = useState("");
  const [uploaderEnImagePreviewUrl, setUploaderEnImagePreviewUrl] = useState(
    ""
  );
  const [uploaderFrImagePreviewUrl, setUploaderFrImagePreviewUrl] = useState(
    ""
  );

  const [enImagePreviewUrl, setEnImagePreviewUrl] = useState();
  const [frImagePreviewUrl, setFrImagePreviewUrl] = useState();

  const [updateComicResult, updateComic] = useMutation(UpdateComic);
  const [createComicResult, createComic] = useMutation(CreateComic);
  const [removeComicResult, removeComic] = useMutation(RemoveComic);

  useEffect(() => {
    setEnTitle(comicObject.enTitle);
    setFrTitle(comicObject.frTitle);
    setEnDescription(comicObject.enDescription);
    setFrDescription(comicObject.frDescription);
    setEnImagePreviewUrl(comicObject.enImg);
    setFrImagePreviewUrl(comicObject.frImg);
  }, [
    comicObject.enTitle,
    comicObject.frTitle,
    comicObject.enDescription,
    comicObject.frDescription,
    comicObject.enImg,
    comicObject.frImg,
  ]);

  const comicPayload = () => {
    return {
      id: newMode ? null : comicObject.id,
      enTitle: enTitle,
      frTitle: frTitle,
      enDescription: enDescription,
      frDescription: frDescription,
      enImg: uploaderEnImagePreviewUrl || enImagePreviewUrl,
      frImg: uploaderFrImagePreviewUrl || frImagePreviewUrl,
    };
  };

  const onUpdate = () => {
    updateComic(comicPayload()).then((res) => {
      if (res.error) {
        toast.error("Place make sure all inputs are valid.");
      } else {
        toast.success("Comic is updated");
      }
    });
  };

  const onCreate = () => {
    createComic(comicPayload()).then((res) => {
      if (res.error) {
        toast.error("Place make sure all inputs are valid.");
      } else {
        toast.success("New comic is created");
        comicOnCreate();
      }
    });
  };

  const onDelete = () => {
    removeComic({
      id: comicObject.id,
    }).then((res) => {
      if (res.error) {
        toast.error("Unable to remove comic, please try again later.");
      } else {
        toast.success("Comic has been removed");
        comicOnDelete();
      }
    });
  };

  return (
    <Form className="comic-editor">
      <Form.Group>
        <Row>
          <Col>
            <Form.Label>Comic {comicObject.id} Image (English)</Form.Label>
            {enImagePreviewUrl && (
              <div className="image_preview_container">
                <img
                  className="image_preview"
                  src={enImagePreviewUrl}
                  alt="image_preview"
                />
              </div>
            )}

            <ImageUploader
              imageOnUploaded={(fileUrl) =>
                setUploaderEnImagePreviewUrl(fileUrl)
              }
            />
            {uploaderEnImagePreviewUrl && (
              <div className="image_uploader_preview_container">
                <img
                  className="image_uploader_preview"
                  src={uploaderEnImagePreviewUrl}
                  alt="image_preview"
                />
              </div>
            )}
          </Col>
          <Col>
            <Form.Label>Comic {comicObject.id} Image (French)</Form.Label>
            {frImagePreviewUrl && (
              <div className="image_preview_container">
                <img
                  className="image_preview"
                  src={frImagePreviewUrl}
                  alt="image_preview"
                />
              </div>
            )}

            <ImageUploader
              imageOnUploaded={(fileUrl) =>
                setUploaderFrImagePreviewUrl(fileUrl)
              }
            />
            {uploaderFrImagePreviewUrl && (
              <div className="image_uploader_preview_container">
                <img
                  className="image_uploader_preview"
                  src={uploaderFrImagePreviewUrl}
                  alt="image_preview"
                />
              </div>
            )}
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col>
            <Form.Label>Comic {comicObject.id} Title (English) </Form.Label>
            <Form.Control
              type="text"
              placeholder="Comic Title"
              value={enTitle}
              onChange={(e) => setEnTitle(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Label>Comic {comicObject.id} Title (French) </Form.Label>
            <Form.Control
              type="text"
              placeholder="Comic Title"
              value={frTitle}
              onChange={(e) => setFrTitle(e.target.value)}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col>
            <Form.Label>
              Comic {comicObject.id} Description (English)
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Comic Description"
              value={enDescription}
              onChange={(e) => setEnDescription(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Label>Comic {comicObject.id} Description (French)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Comic Description"
              value={frDescription}
              onChange={(e) => setFrDescription(e.target.value)}
            />
          </Col>
        </Row>
      </Form.Group>

      <Button variant="primary" onClick={newMode ? onCreate : onUpdate}>
        {newMode ? "Save" : "Update"}
      </Button>

      {!newMode && (
        <Button variant="danger" onClick={onDelete} className="remove-button">
          Remove
        </Button>
      )}
    </Form>
  );
};

export default ComicEditor;
