import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import Cookies from "js-cookie";

const DashboardNavBar = ({ clientUrl }) => {
  const logoutOnClick = () => {
    Cookies.set("auth-token", "");
    Cookies.set("remember-me", "");
    window.location.reload(false);
  };

  return (
    <Navbar bg="light" variant="light" fixed="top">
      <Navbar.Brand href="#home">Boxia Management Panel</Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link href={clientUrl} target="_blank">
          Home
        </Nav.Link>
      </Nav>
      <Button variant="outline-success" onClick={logoutOnClick}>
        Logout
      </Button>
    </Navbar>
  );
};

export default DashboardNavBar;
