import React, { useState, useEffect } from "react";
import "../style/cultural_billboard_editor.scss";
import { Button, Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMutation } from "urql";
import ImageUploader from "./ImageUploader";

const UpdateCulturalBillboard = `
  mutation ($id: ID!, $img:String! $enTitle: String!, $frTitle: String!) {
    updateCulturalBillboard (id: $id, img:$img, enTitle: $enTitle, frTitle: $frTitle) {
      id
    }
  }
`;

const CulturalBillboardEditor = ({ culturalBillboardObject = {} }) => {
  const [enTitle, setEnTitle] = useState("");
  const [frTitle, setFrTitle] = useState("");
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [updateCulturalBillboardResult, updateCulturalBillboard] = useMutation(
    UpdateCulturalBillboard
  );

  const getCulturalBillboardPayload = () => {
    return {
      id: 1,
      img: previewImage || image,
      enTitle: enTitle,
      frTitle: frTitle,
    };
  };

  useEffect(() => {
    setEnTitle(culturalBillboardObject.enTitle);
    setFrTitle(culturalBillboardObject.frTitle);
    setImage(culturalBillboardObject.image);
  }, [
    culturalBillboardObject.enTitle,
    culturalBillboardObject.frTitle,
    culturalBillboardObject.image,
  ]);

  const onUpdate = (e) => {
    e.preventDefault();

    updateCulturalBillboard(getCulturalBillboardPayload()).then((res) => {
      if (res.error) {
        toast.error("Please make sure all inputs are valid.");
      } else {
        toast.success("Cultural Billboard is updated");
        setPreviewImage(null);
      }
    });
  };

  return (
    <Form className="cultural-billboard-editor">
      <Form.Group>
        <Row>
          <Col>
            <Form.Label>Image (English)</Form.Label>
            {image && (
              <div className="image_preview_container">
                <img
                  className="image_preview"
                  src={image}
                  alt="image_preview"
                />
              </div>
            )}

            <ImageUploader
              imageOnUploaded={(fileUrl) => setPreviewImage(fileUrl)}
            />
            {previewImage && (
              <div className="image_uploader_preview_container">
                <img
                  className="image_uploader_preview"
                  src={previewImage}
                  alt="image_preview"
                />
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label> Title (English) </Form.Label>
            <Form.Control
              type="text"
              placeholder="Title (English)"
              value={enTitle}
              onChange={(e) => setEnTitle(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Label> Title (French) </Form.Label>
            <Form.Control
              type="text"
              placeholder="Title (French)"
              value={frTitle}
              onChange={(e) => setFrTitle(e.target.value)}
            />
          </Col>
        </Row>
      </Form.Group>

      <Button variant="primary" onClick={onUpdate}>
        Update
      </Button>
    </Form>
  );
};

export default CulturalBillboardEditor;
