import React from "react";
import "./style/loginpanel.scss";
import { Container, Card, Button, Form, Alert } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";

class LoginPanel extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      loading: null,
      success: false,
      rememberMe: false,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { email, password, rememberMe } = this.state;
    const { loginOnSuccess, serverUrl } = this.props;

    axios
      .post(`${serverUrl}/authenticate`, {
        email: email,
        password: password,
      })
      .then((res) => {
        this.setState({
          loading: false,
          success: true,
        });

        Cookies.set("auth-token", res.data.auth_token);
        Cookies.set("remember-me", `${rememberMe}`);

        loginOnSuccess();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.setState({
            loading: false,
            success: false,
          });
        } else {
          window.alert(
            "Service is unavailable right now, please try again later"
          );
        }
      });
  };

  render() {
    const { loading, success } = this.state;
    return (
      <Container>
        <Card>
          <Card.Header>Welcome to Boxia admin panel</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Remember Me"
                  onClick={(e) =>
                    this.setState({ rememberMe: e.target.checked })
                  }
                />
              </Form.Group>

              <Button variant="primary" type="submit" onClick={this.onSubmit}>
                Submit
              </Button>
            </Form>

            <Alert
              variant={`${success ? "success" : "danger"}`}
              className={`login-message ${
                loading === null ? "hidden" : "shown"
              }`}
            >
              {success
                ? "Login successful!"
                : "Incorrect email or password, please try again."}
            </Alert>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default LoginPanel;
