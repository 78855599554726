import React from "react";
import "./style/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import LoginPanel from "./LoginPanel";
import ManagementPanel from "./ManagementPanel";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createClient, Provider } from "urql";
import axios from "axios";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      successLogin: false,
      serverUrl:
        window.location.hostname == "boxia-admin.boxia.ca"
          ? "https://boxia-core.boxia.ca"
          : process.env.NODE_ENV === "production"
          ? "https://boxia-core.dokku.explorator.ca"
          : "http://localhost:4000",
      clientUrl:
        window.location.hostname == "boxia-admin.boxia.ca"
          ? "https://boxia.boxia.ca"
          : process.env.NODE_ENV === "production"
          ? "https://boxia.dokku.explorator.ca"
          : "http://localhost:3000",
    };

    this.createOrUpdateClient();
  }

  createOrUpdateClient() {
    const { serverUrl } = this.state;

    this.client = createClient({
      url: `${serverUrl}/graphql`,
      fetchOptions: () => {
        const token = Cookies.get("auth-token") || "";
        return {
          headers: { authorization: token ? `Bearer ${token}` : "" },
        };
      },
    });
  }

  componentDidMount() {
    const { serverUrl } = this.state;
    const authToken = Cookies.get("auth-token");
    if (authToken !== undefined && Cookies.get("remember-me") === "true") {
      axios
        .post(
          serverUrl + "/token_authenticate",
          {},
          {
            headers: { authorization: `Bearer ${authToken}` },
          }
        )
        .then((authResponse) => {
          if (authResponse.data.token_check_result === true) {
            this.setState(
              {
                successLogin: true,
              },
              this.createOrUpdateClient
            );
          }
        })
        .catch(() => {});
    }
  }

  render() {
    const { successLogin, serverUrl, clientUrl } = this.state;

    return (
      <Provider value={this.client}>
        <div className="App">
          <Container fluid className="root-container">
            {successLogin ? (
              <ManagementPanel clientUrl={clientUrl} />
            ) : (
              <LoginPanel
                serverUrl={serverUrl}
                loginOnSuccess={() =>
                  this.setState(
                    { successLogin: true },
                    this.createOrUpdateClient
                  )
                }
              />
            )}
          </Container>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </Provider>
    );
  }
}

export default App;
