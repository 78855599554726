import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import FroalaEditorComponent from "react-froala-wysiwyg";
import { useMutation } from "urql";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { getServerUrl } from "../Utils";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

const UpdateWhatsNew = `
  mutation ($enContent: String!, $frContent: String!) {
    updateWhatsNew (enContent: $enContent, frContent: $frContent) {
      enContent
      frContent
    }
  }
`;

const WhatsNewEditor = ({ whatsNew }) => {
  const [enContent, setEnContent] = useState("");
  const [frContent, setFrContent] = useState("");
  const [updateWhatsNewResult, updateWhatsNew] = useMutation(UpdateWhatsNew);

  useEffect(() => {
    setEnContent(whatsNew.enContent);
    setFrContent(whatsNew.frContent);
  }, [whatsNew.enContent, whatsNew.frContent]);

  const config = {
    placeholderText: "Edit Your Content Here!",
    events: {},
    pluginsEnabled: ["image"],
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "subscript",
      "superscript",
      "|",
      "fontFamily",
      "fontSize",
      "color",
      "inlineStyle",
      "paragraphStyle",
      "|",
      "paragraphFormat",
      "align",
      "formatOL",
      "formatUL",
      "outdent",
      "indent",
      "quote",
      "check",
      "|",
      "insertLink",
      "insertImage",
      "insertVideo",
      "embedly",
      "insertFile",
      "insertTable",
      "|",
      "emoticons",
      "specialCharacters",
      "insertHR",
      "selectAll",
      "clearFormatting",
      "|",
      "spellChecker",
      "help",
      "html",
      "|",
      "undo",
      "redo",
    ],
    imageUploadURL: `${getServerUrl()}/image_upload`,
    requestHeaders: {
      authorization: Cookies.get("auth-token"),
    },
  };

  const saveOnClick = (e) => {
    e.preventDefault();

    updateWhatsNew({
      enContent: enContent,
      frContent: frContent,
    }).then((res) => {
      if (res.error) {
        toast.error("Please make sure all inputs are valid.");
      } else {
        toast.success("What's new section is updated");
      }
    });
  };

  return (
    <Form className="whatsnew-editor">
      <Form.Group>
        <Row>
          <Col>
            <Form.Label>English</Form.Label>
            <FroalaEditorComponent
              tag="textarea"
              config={config}
              model={enContent}
              onModelChange={setEnContent}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>French</Form.Label>
            <FroalaEditorComponent
              tag="textarea"
              config={config}
              model={frContent}
              onModelChange={setFrContent}
            />
          </Col>
        </Row>
      </Form.Group>

      <Button variant="primary" onClick={saveOnClick}>
        Save
      </Button>
    </Form>
  );
};

export default WhatsNewEditor;
